define("@m3u8/frontend/utils/regex-url", ["exports", "@m3u8/frontend/utils/regex-ip", "@m3u8/frontend/utils/tlds"], function (_exports, _regexIp, _tlds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = regexUrl;

  function regexUrl(string, options = {
    strict: true
  }) {
    const protocol = `(?:(?:[a-z]+:)?//)${options.strict ? '' : '?'}`;
    const auth = '(?:\\S+(?::\\S*)?@)?';
    const ip = (0, _regexIp.default)();
    const host = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
    const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
    const tld = `(?:\\.${options.strict ? '(?:[a-z\\u00a1-\\uffff]{2,})' : `(?:${(0, _tlds.default)().sort((a, b) => b.length - a.length).join('|')})`})\\.?`;
    const port = '(?::\\d{2,5})?';
    const path = '(?:[/?#][^\\s"]*)?';
    const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ip}|${host}${domain}${tld})${port}${path}`;
    const pattern = options.exact ? new RegExp(`(?:^${regex}$)`, 'i') : new RegExp(regex, 'ig');
    return typeof string === 'string' ? string.match(pattern) : false;
  }
});