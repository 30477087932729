define("@m3u8/frontend/services/pusher", ["exports", "pusher-js", "@m3u8/frontend/config/environment"], function (_exports, _pusherJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PusherService extends Ember.Service {
    /** @constructor */
    constructor() {
      super(...arguments);
      const pusher = new _pusherJs.default(_environment.default.pusher.appKey, {
        cluster: _environment.default.pusher.cluster || 'ap1'
      });
      this.subscribe = pusher.subscribe.bind(pusher);
      this.bind_global = pusher.bind_global.bind(pusher);
    }

  }

  _exports.default = PusherService;
});