define("@m3u8/frontend/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationsService extends _notifications.default {
    /**
     * Handles errors from the server
     *
     * @param {Error} error
     * @void
     */
    serverError(error, fallbackMessage = 'Oops! Something went wrong with your request.', options) {
      if (Ember.isArray(error.errors)) {
        const errorMessage = error.errors.firstObject;
        return this.error(errorMessage || fallbackMessage, options);
      }

      if (error instanceof Error) {
        return this.error(error.message || fallbackMessage, options);
      }

      return this.error(fallbackMessage, options);
    }

  }

  _exports.default = NotificationsService;
});