define("@m3u8/frontend/controllers/application", ["exports", "@m3u8/frontend/utils/regex-url"], function (_exports, _regexUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "pusher", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "urls", _descriptor4, this);

      _initializerDefineProperty(this, "files", _descriptor5, this);

      _initializerDefineProperty(this, "downloadSetting", _descriptor6, this);

      _initializerDefineProperty(this, "isLoading", _descriptor7, this);
    }

    /**
     * Current year
     *
     * @var {String}
     */
    get currentYear() {
      return new Date().getFullYear();
    }
    /**
     * Adds a new URL entry after a text input has text input, if empty remove the next url entry
     * if it is also empty
     *
     * @void
     */


    submit(urlDump, event) {
      event.preventDefault();
      const urls = (0, _regexUrl.default)(urlDump);

      if (!urls) {
        this.notifications.error('You must enter one or more valid m3u8 urls!');
        return;
      }

      this.setIsLoading(true);
      this.fetch.post('process', {
        urls
      }).then(this.setFiles).catch(this.logError).finally(this.setIsLoading);
    }

    reset() {
      this.setFiles();
      this.setUrls();
      this.setIsLoading();
    }

    setFiles(files = []) {
      this.files = files;
    }

    setUrls(urls = []) {
      this.urls = urls;
    }

    setIsLoading(isLoading = false) {
      this.isLoading = isLoading;
    }

    logError(error) {
      console.log(error);
      this.notifications.serverError(error);
    }
    /**
     * Sends request for user to download file
     *
     * @void
     */


    download(file) {
      Ember.set(file, 'downloading', true);
      this.fetch.download(`download`, {
        file: file.session
      }, {
        fileName: `${file.session}.mp4`,
        mimeType: 'video/mp4'
      }).finally(() => {
        Ember.set(file, 'downloading', false);
      });
    }
    /**
     * Connect to the process server session
     * 
     * @void
     */


    connectSession(file, element) {
      let {
        session
      } = file;
      let channel = this.pusher.subscribe(session);
      /** @var {HTMLElement} outputDOM */

      let outputDOM = element.querySelector('.session-output .session-output-text');
      /** @var {HTMLElement} progressDOM */

      let progressDOM = element.querySelector('.session-progress');
      channel.bind('conversion.updated', data => {
        // console.log('converion.updated', data);
        if (data?.type === 'completed') {
          return Ember.set(file, 'ready', true);
        }

        if (data?.type === 'error') {
          Ember.set(file, 'error', true);
          progressDOM.classList.add('hidden');
          outputDOM.innerHTML = `<span class="text-red-500 font-semibold text-sm">${data.message ? data.message : 'Error! Unable to convert file.'}</span>`;
          channel.unbind('conversion.updated');
          return;
        }

        let output = `Frame: ${data.frame_count} of ${data.frame_count_total}, Time: ${data.time}, ETA: ${data.eta}, Percent: ${data.percent}%`;

        if (outputDOM && data.percent <= 100) {
          outputDOM.innerHTML = output;
        }

        if (progressDOM && data.percent <= 100) {
          progressDOM.value = data.percent;
        }

        if (progressDOM.classList.contains('hidden')) {
          progressDOM.classList.remove('hidden');
        }

        if (data.percent > 100) {
          Ember.set(file, 'ready', true);
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pusher", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "urls", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "downloadSetting", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'download_now';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFiles", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setUrls", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setUrls"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsLoading", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setIsLoading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logError", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "logError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "connectSession", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "connectSession"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});