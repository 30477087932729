define("@m3u8/frontend/utils/regex-ip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = regexIp;

  function regexIp(options = {}) {
    const word = '[a-fA-F\\d:]';

    const b = options => options && options.includeBoundaries ? `(?:(?<=\\s|^)(?=${word})|(?<=${word})(?=\\s|$))` : '';

    const v4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
    return new RegExp(`${b(options)}${v4}${b(options)}`, 'g');
  }
});