define("@m3u8/frontend/routes/application", ["exports", "@m3u8/frontend/config/environment", "socketcluster"], function (_exports, _environment, _socketcluster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends Ember.Route {
    async beforeModel() {// config.host = 'localhost';
      // const socket = socketcluster.create(config.socket);
      // const channel = socket.subscribe('test');
      // await channel.listener('subscribe').once();
      // console.log(channel);
      // (async () => {
      //     for await (let data of channel) {
      //         console.log(data);
      //     }
      // })();
    }

  }

  _exports.default = ApplicationRoute;
});